import React, { useState } from 'react';
import { jsPDF } from 'jspdf'; // Import jspdf
import 'jspdf-autotable'; // Import jspdf-autotable
import logo from '../assets/lis.jpg'; // Importar imagen
import axios from 'axios';
import checkToken from './jwtCheck';

import {useParams,  useNavigate } from 'react-router-dom';
function CowForm() {
  //
  const navigate = useNavigate();
  const { codigo_arete } = useParams();
  const token = localStorage.token;
  let insercionAnterior;
  let colocacionPezonAnterior;
  let longitudPezon;
  let profundidadUbre;
  let alturaUbrePosterior;
  let ligamentosSuspensorMedio;
  let colocacionPezonPosterior;
  let anchuraUbreTrasera;
  let inclinacionUbre;

  let angularidad;
  let fortaleza;

  let anguloPezunas;
  let patasVistaLateral;
  let locomocion;
  let patasVistaPosterior;
  let coxoFemoral;

  let estatura;
  let profundidad;
  let condicionCorporal;
  let anguloGrupa;
  let anchoGrupa;
  let peso;
  let talla;
  let homogenidadCapa;
  let score;

  let nombre;
  let raza;
  let foto;
  let fechaNac;
  let codigo_aretepdf;
 const [fotoBase64, setFotoBase64] = useState('');
  const obtnerdatos = async () => {
    
    try {
      const response = await axios.get(`https://cowsmyapi.mycowsrfi.com/api/rfi/obtenerRfiLast/${codigo_arete}`,
        {
          headers:{
            'Content-Type': 'application/json',
           'Authorization': localStorage.token
          }
        }
      );
      codigo_aretepdf=response.data.datos.codigo_arete;
      alturaUbrePosterior=response.data.datos.altura_de_la_ubre_posterior;
      anchoGrupa=response.data.datos.ancho_de_la_grupa;
      anchuraUbreTrasera=response.data.datos.anchura_de_la_ubre_trasera;
      angularidad=response.data.datos.angularidad;
      anguloGrupa=response.data.datos.angulo_de_la_grupa;
      anguloPezunas=response.data.datos.angulo_de_pezunas;
      colocacionPezonAnterior=response.data.datos.colocacion_de_pezon_anterior;
      colocacionPezonPosterior=response.data.datos.colocacion_de_pezon_posterior;
      condicionCorporal=response.data.datos.condicion_corporal;
      coxoFemoral=response.data.datos.coxo_femoral;
      estatura=response.data.datos.estatura;
      fortaleza=response.data.datos.fortaleza;
      inclinacionUbre=response.data.datos.inclinacion_de_la_ubre;
      insercionAnterior=response.data.datos.insercion_anterior_de_la_ubre;
      ligamentosSuspensorMedio=response.data.datos.ligamentos_suspensor_medio;
      locomocion=response.data.datos.locomocion;
      longitudPezon=response.data.datos.longitud_de_pezon;
      patasVistaLateral=response.data.datos.patas_vista_lateral;
      patasVistaPosterior=response.data.datos.patas_vista_posterior;
      profundidad=response.data.datos.profundidad;
      profundidadUbre=response.data.datos.profundidad_de_la_ubre;
      const responsevaca = await axios.get(`https://cowsmyapi.mycowsrfi.com/api/vacas/${codigo_arete}`,
        {
          headers:{
            'Content-Type': 'application/json',
           'Authorization': localStorage.token
          }
        }
      );
      fechaNac= responsevaca.data.data.fecha_nacimiento;
      foto= responsevaca.data.data.foto;
      homogenidadCapa= responsevaca.data.data.homogenidad_capa;
      nombre= responsevaca.data.data.nombre;
      peso= responsevaca.data.data.peso;
      score= responsevaca.data.data.puntaje;
      raza= responsevaca.data.data.raza;
      talla= responsevaca.data.data.talla;
      llenardatos();
    } catch (error) {
      console.error(error);
    }
  };

  obtnerdatos();
  const llenardatos =() => {
  const cowData= {
    arete: codigo_aretepdf,
    nombre: nombre,
    raza: raza,
    fechaNac: fechaNac,
    homogeCapa: homogenidadCapa,
    peso: peso,
    talla: talla,
    puntaje: score,
  };

  const tableRows=[{
      caracteristica: 'Inserción anterior de la ubre',
      calificacion: insercionAnterior,
    },
    
    {
      caracteristica: 'Colocación de pezón anterior',
      calificacion: colocacionPezonAnterior,
    },
    {
      caracteristica: 'Longitud de pezón',
      calificacion: longitudPezon,
    },
    {
      caracteristica: 'Profundidad de la ubre',
      calificacion: profundidadUbre,
    },
    {
      caracteristica: 'Altura de la ubre posterior',
      calificacion: alturaUbrePosterior,
    },
    {
      caracteristica: 'Ligamentos suspensor medio',
      calificacion: ligamentosSuspensorMedio,
    },
    {
      caracteristica: 'Colocación de pezon posterior',
      calificacion: colocacionPezonPosterior,
    },
    {
      caracteristica: 'Anchura de la ubre trasera',
      calificacion: anchuraUbreTrasera,
    },
    {
      caracteristica: 'Inclinación de la ubre',
      calificacion: inclinacionUbre,
    },
  
  ];

  const tableRows2=[
    {
      caracteristica: 'Angularidad',
      calificacion: angularidad,
    },
    
    {
      caracteristica: 'Fortaleza',
      calificacion: fortaleza,
    },
   
  ];
  const tableRows3=[
    {
      caracteristica: 'Ángulo de pezuñas',
      calificacion: anguloPezunas,
    },
    
    {
      caracteristica: 'Patas vista lateral ',
      calificacion: patasVistaLateral,
    },
    {
      caracteristica: 'Locomoción',
      calificacion: locomocion,
    },
   
    {
      caracteristica: 'Patas vista posterior',
      calificacion: patasVistaPosterior,
    },
   
    {
      caracteristica: 'Coxo femoral',
      calificacion: coxoFemoral,
    },
   
   
  ];
  const tableRows4=[
    {
      caracteristica: 'Estatura',
      calificacion: estatura,
    },
    
    {
      caracteristica: 'Profundidad',
      calificacion: profundidad,
    },
    {
      caracteristica: 'Condición corporal',
      calificacion: condicionCorporal,
    },
  ];
  const tableRows5=[
    {
      caracteristica: 'Ángulo de la grupa',
      calificacion: anguloGrupa,
    },
    
    {
      caracteristica: 'Ancho de la grupa',
      calificacion: anchoGrupa,
    },
  ];

  const doc = new jsPDF();
    
    // Add background image as watermark

    const backgroundImage = `${process.env.PUBLIC_URL}/pdf.png`; // Construct the URL
    doc.addImage(backgroundImage, 'PNG', 0, 50, 210, 210, 'slow', 0.8); // Adjust the opacity to 0.2 for a watermark effect
    
    doc.setLineWidth(1); // Grosor del borde
      doc.setDrawColor(0); // Color del borde
      doc.rect(6, 10, 198, 285, "S");
  // Agregar título
  doc.setFontSize(24);
  doc.setFont("verdana", "bold");

  doc.setFillColor(249, 177, 110); // Color tabaco
  doc.rect(0, 0, 210, 30, "F"); // Franja de título
  doc.setTextColor(0); // Texto blanco
  doc.text("REPORTE MY COWS RFI", 60, 20); 


      
  // Agregar imagen en la esquina superior derecha
  const logoWidth = 50; // Ancho de la imagen
  const logoHeight = 50; // Alto de la imagen
  const marginX = 120; // Margen horizontal
  const marginY = 40; // Margen vertical
  doc.addImage(foto, 'JPEG', marginX, marginY, logoWidth, logoHeight);
      // Add basic information
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(`Arete N: ${cowData.arete}`, 10, 40);

      doc.setFontSize(14);
      doc.setFont("Montserrat", "bold");
      doc.text(`Nombre: ${cowData.nombre}`, 10, 53);
      doc.text(`Raza: ${cowData.raza}`, 10, 65);
      doc.text(`Fecha nac: ${cowData.fechaNac}`, 10, 77);
      doc.text(`Homoge. Capa: ${cowData.homogeCapa}`, 10, 89);
      doc.text(`Peso: ${cowData.peso}kg`, 10, 102);
      doc.text(`Talla: ${cowData.talla} m`, 10, 114);

      // Add puntaje
      doc.setFontSize(16);
  doc.setFillColor(89, 96, 172); // Blue fill
  const rectX = 120;
  const rectWidth = 50;
  doc.roundedRect(rectX, 94, rectWidth, 25, 10, 10, "DF");

  const text = `PUNTAJE`;
  const textSize = 16;
  const textWidth = doc.getTextWidth(text, textSize);
  const centerX = rectX + (rectWidth / 2);
  const textX = centerX - (textWidth / 2);
  doc.setTextColor(255); // White text
  doc.text(text, textX, 102);

  doc.setFontSize(18);
  const scoreText = `${cowData.puntaje}/100`;
  const scoreTextWidth = doc.getTextWidth(scoreText, 18);
  const scoreTextX = centerX - (scoreTextWidth / 2);
  doc.text(scoreText, scoreTextX, 112);


  // Establece la posición y el tamaño de la línea
  var startX = 10; // posición x de inicio de la línea
  var startY = 124; // posición y de inicio de la línea
  var endX = 200; // posición x de fin de la línea
  var endY = startY; // posición y de fin de la línea (misma que startY)

  // Dibuja la línea
  doc.setLineWidth(1); // grosor de la línea
  doc.setDrawColor(0); // color de la línea
  doc.line(startX, startY, endX, endY);

      // Add table



  // Establece la familia de fuentes a Lato

  doc.setFont('times', 'bold');

  // Establece el tamaño del texto
  doc.setFontSize(28);


  // Obtiene el ancho del texto
  var textWidth1 = doc.getTextWidth('Sistema Mamario', 28);

  // Calcula la posición x para centrar el texto
  var centerX1 = 60; // ya que el texto se dibuja en x = 60
  doc.setFillColor(244, 176, 132); // blanco


  // Dibuja la franja de título en la posición del texto
  doc.rect(centerX1 - 42, 125, textWidth1 + 85, 12, "F");

  // Dibuja el texto
  doc.text('Sistema Mamario', centerX1, 133);

      const tableData = [
        // Header row
        ['CARACTERÍSTICAS', 'CALIFICACIÓN'],
        // Data rows from tableRows
        ...tableRows.map((row) => [row.caracteristica, row.calificacion]),
      ];

      doc.autoTable({
        head: tableData.slice(0, 1),
        body: tableData.slice(1),
        startY: 140, // Start position of the table
        theme: 'grid', // Use grid theme
        styles: {
          cellPadding: 2, // Add padding
          fontSize: 11, // Adjust font size
        },
        headStyles: {
          fillColor: [244, 176, 132], // Establece el color de fondo de la cabecera
          textColor: [255, 255, 255], // Establece el color del texto de la cabecera
        },
        columnStyles: {
          0: { cellWidth: 120 }, // Set width of first column
          1: { 
              cellWidth: 40,
              halign: 'center'
          }, // Set width of second column
        },
        margin: { left: 18 }, // Agrega un margen izquierdo de 20 unidades
      });
        var tableWidth = doc.internal.pageSize.getWidth() - 2 * 30; // Ajusta el ancho para considerar los márgenes

      // Add table2
      doc.setFont('times', 'bold');
      doc.setFontSize(28);
      // Obtiene el ancho del texto
  var textWidth2 = doc.getTextWidth('Fuerza Lechera', 28);

  // Calcula la posición x para centrar el texto
  var centerX2 = 60; // ya que el texto se dibuja en x = 60
  doc.setFillColor(169, 208, 142); // blanco


  // Dibuja la franja de título en la posición del texto
  doc.rect(centerX2 - 42, 235, textWidth2 + 94, 12, "F");

  // Dibuja el texto
  doc.text('Fuerza Lechera', centerX1, 245);
      const tableData2 = [
        // Header row
        ['CARACTERÍSTICAS', 'CALIFICACIÓN'],
        // Data rows from tableRows
        ...tableRows2.map((row) => [row.caracteristica, row.calificacion]),
      ];
  
      doc.autoTable({
        head: tableData2.slice(0, 1),
        body: tableData2.slice(1),
        startY: 248, // Start position of the table
        theme: 'grid', // Use grid theme
        styles: {
          cellPadding: 2, // Add padding
          fontSize: 11, // Adjust font size
        },
        headStyles: {
          fillColor: [169, 208, 142], // Establece el color de fondo de la cabecera
          textColor: [255, 255, 255], // Establece el color del texto de la cabecera
        },
        columnStyles: {
          0: { cellWidth: 120 }, // Set width of first column
          1: { cellWidth: 40,
            halign:'center'
            }, // Set width of second column
        },
        margin: { left: 18 },
      });

      var tableWidth = doc.internal.pageSize.getWidth() - 2 * 30; // Ajusta el ancho para considerar los márgenes
        // ...
        doc.setFontSize(12);
        doc.setTextColor(128); // Texto gris
        doc.text("Página 1 de 2", 10, 280);
      doc.text("Fecha: " + new Date().toLocaleDateString(), 10, 290);
      doc.text("Hora: " + new Date().toLocaleTimeString(), 10, 300);
      

        doc.addPage();
        const backgroundImage1 = `${process.env.PUBLIC_URL}/pdf.png`; // Construct the URL
      doc.addImage(backgroundImage1, 'PNG', 0, 50, 210, 210, 'slow', 0.8); // Adjust the opacity to 0.2 for a watermark effect
    
        doc.setLineWidth(1); // Grosor del borde
        doc.setDrawColor(0); // Color del borde
        doc.rect(6, 10, 198, 285, "S"); // Dibuja el borde alrededor de la página
        
        // Add table3
        doc.setFont('times', 'bold');
        doc.setFontSize(28);
        // Obtiene el ancho del texto
        var textWidth3 = doc.getTextWidth('Patas y Pezuñas ', 28);
        doc.setTextColor(255); // Texto gris
        // Calcula la posición x para centrar el texto
        var centerX3 = 60; // ya que el texto se dibuja en x = 60
        doc.setFillColor(255, 217, 102); // blanco
        
        
        // Dibuja la franja de título en la posición del texto
        doc.rect(centerX3 - 42, 28, textWidth3 + 90, 12, "F");
        
        // Dibuja el texto
        doc.text('Patas y Pezuñas ', centerX3, 36);
        const tableData3 = [
          // Header row
          ['CARACTERÍSTICAS', 'CALIFICACIÓN'],
          // Data rows from tableRows
          ...tableRows3.map((row) => [row.caracteristica, row.calificacion]),
        ];
        
        doc.autoTable({
          head: tableData3.slice(0, 1),
          body: tableData3.slice(1),
          startY: 41, // Start position of the table
          theme: 'grid', // Use grid theme
          styles: {
            cellPadding: 2, // Add padding
            fontSize: 11, // Adjust font size
          },
          headStyles: {
            fillColor: [255, 217, 102], // Establece el color de fondo de la cabecera
            textColor: [255, 255, 255], // Establece el color del texto de la cabecera
          },
          columnStyles: {
            0: { cellWidth: 120 }, // Set width of first column
            1: { cellWidth: 40,
            halign:'center'
            }, // Set width of second column
          },
          margin: { left: 18 },
        });

        // Add table4
  doc.setFont('times', 'bold');
  doc.setFontSize(28);
  // Get the width of the text
  var textWidth4 = doc.getTextWidth('Tren Anterior y Capacidad', 28);
  doc.setTextColor(255); // Texto gris
  // Calculate the position x to center the text
  var centerX4 = 60; // since the text is drawn at x = 60
  doc.setFillColor(132, 151, 176); // blanco

  // Draw the title band at the position of the text
  doc.rect(centerX4 - 42, 93, textWidth4 + 46, 12, "F");

  // Draw the text
  doc.text('Tren Anterior y Capacidad', centerX4-20, 102);

  const tableData4 = [
    // Header row
    ['CARACTERÍSTICAS', 'CALIFICACIÓN'],
    // Data rows from tableRows4
    ...tableRows4.map((row) => [row.caracteristica, row.calificacion]),
  ];

  doc.autoTable({
    head: tableData4.slice(0, 1),
    body: tableData4.slice(1),
    startY: 106, // Start position of the table
    theme: 'grid', // Use grid theme
    styles: {
      cellPadding: 2, // Add padding
      fontSize: 11, // Adjust font size
    },
    headStyles: {
      fillColor: [132, 151, 176], // Set background color of the header
      textColor: [255, 255, 255], // Set text color of the header
    },
    columnStyles: {
      0: { cellWidth: 120 }, // Set width of first column
      1: { cellWidth: 40, halign: 'center' }, // Set width of second column
    },
    margin: { left: 18 },
  });

  // Add table5
  doc.setFont('times', 'bold');
  doc.setFontSize(28);
  // Get the width of the text
  var textWidth5 = doc.getTextWidth('Grupa ', 28);
  doc.setTextColor(255); // Texto gris
  // Calculate the position x to center the text
  var centerX5 = 60; // since the text is drawn at x = 60
  doc.setFillColor(174, 170, 170); // blanco

  // Draw the title band at the position of the text
  doc.rect(centerX5 - 42, 141, textWidth5 + 130, 12, "F");

  // Draw the text
  doc.text('Grupa', centerX5+30, 149);

  const tableData5 = [
    // Header row
    ['CARACTERÍSTICAS', 'CALIFICACIÓN'],
    // Data rows from tableRows5
    ...tableRows5.map((row) => [row.caracteristica, row.calificacion]),
  ];

  doc.autoTable({
    head: tableData5.slice(0, 1),
    body: tableData5.slice(1),
    startY: 154, // Start position of the table
    theme: 'grid', // Use grid theme
    styles: {
      cellPadding: 2, // Add padding
      fontSize: 11, // Adjust font size
    },
    headStyles: {
      fillColor: [174, 170, 170], // Set background color of the header
      textColor: [255, 255, 255], // Set text color of the header
    },
    columnStyles: {
      0: { cellWidth: 120 }, // Set width of first column
      1: { cellWidth: 40, halign: 'center' }, // Set width of second column
    },
    margin: { left: 18 },
  })
        
      // Agregar un pie de página
    doc.setFontSize(12);
    doc.setTextColor(128); // Texto gris
    doc.text("Página 2 de 2", 10, 280);
    doc.text("Fecha: " + new Date().toLocaleDateString(), 10, 290);
    doc.text("Hora: " + new Date().toLocaleTimeString(), 10, 300);

    
    // Save the PDF

    const isMobile = window.innerWidth < 768;
    const pdfOutput = doc.output('blob'); // Get PDF as data URI
    const pdfUrl = URL.createObjectURL(pdfOutput);
    fetch(pdfUrl)
      .then(res => res.blob())
      .then(blob => {
        // Crear un nuevo archivo a partir del Blob
        const newpdfFile = new File([blob], 'ejemplar_'+codigo_arete+'.pdf', { type: 'pdf' });
        cargarPDF(newpdfFile);
      })
      .catch(err => {
        console.error('Error al convertir el Data URL a Blob:', err);
      });
    if (isMobile) {
    // Open PDF in a new tab for mobile devices
    window.open('https://mycowsrfi.com/docs/ejemplares/ejemplar_'+codigo_arete+'.pdf', '_blank', 'noopener,noreferrer'); // Open in new tab
    } else {
    doc.save(`ejemplar_${codigo_arete}.pdf`);
    }

      navigate(-1);
}
const cargarPDF = async (docPDF) => {
  const newFileName = 'ejemplar_'+codigo_arete+'.pdf';
  const renamedFile = new File([docPDF], newFileName, { type: docPDF.type });
  const formData = new FormData();
  formData.append('document', renamedFile);
  try {
    const respuesta = await axios.post('https://cowsmyapi.mycowsrfi.com/api/subirpdfrfi',formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Asegúrate de establecer el tipo de contenido
      },
    });
      //console.error('La imagen se ha cargado correctamente.'); // Mensaje de éxito
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Mostrar los errores de validación
        console.error(error.response.data.errors);
        console.error(`Error de validación: ${JSON.stringify(error.response.data.errors)}`);
      } else {
          console.error(error);
          console.error(`Error al cargar la imagen: ${error.response?.data?.message || 'Error desconocido'}`);
      }
    }
  //console.log(renamedFile);
}
  return null ;
};

export default CowForm;