import React, { useState, useEffect } from 'react';
import '../Styles/lista.css'
import {useParams,  useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus, faTrash, faEdit, faQrcode, faBarcode, faHistory } from '@fortawesome/free-solid-svg-icons';
import Cabecera from './CabeceraControler';
import axios from 'axios';
import checkToken from './jwtCheck';

function Galeria() {
  const { raza  } = useParams();
  const [tipoUsuario, setTipoUsuario] = useState('');
  const token = localStorage.token;
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [newEjemplar, setNewEjemplar] = useState({ url: '', descripcion: '', codigoArete: '', puntaje: '' });
  const [imagenes, setImagenes] =useState([]);
  const [filteredImagenes, setFilteredImagenes] = useState([]);
  useEffect(() => {
    fetchImagenes();
    limpiarCache();
  }, [raza]);

  useEffect(() => {
    handleSearch();
  }, [search, imagenes]);

  const fetchImagenes = async () => {
    var draza = '';
    if(raza == 'BrownSwiss'){
      draza = 'Brown Swiss'
    }else {
      draza = raza
    }
    try {
      const response = await axios.get(`https://cowsmyapi.mycowsrfi.com/api/vacas/raza/${draza}`,
        {
          headers:{
      
           Authorization: localStorage.token
          }
        }
      );
      if(response.data.code == 200){
        setImagenes(response.data.data);
        setFilteredImagenes(response.data.data);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
    try {
      const userData = await checkToken(token);
      setTipoUsuario(userData.tipo_usuario);
    } catch (error) {
      console.error(error);
    }
  };
 
  if (!token) {
    return (
      <div className='containermsj '>
      <div className='mensaje'>
        <h2>Debe iniciar sesión para acceder a esta página</h2>
        <p className='msjle'>Por favor, inicie sesión para continuar</p>
        <button className='btnmensaje' onClick={() => navigate('/Login')}><a className='textomsj'>Ingresar</a></button>
      </div>
      </div>
    );
  }
  const handleSearch = () => {
    const filtered = imagenes.filter((imagen) => {
      return (
        (imagen.nombre?.toLowerCase().includes(search.toLowerCase()) || '') ||
        (imagen.codigo_arete?.toLowerCase().includes(search.toLowerCase()) || '') ||
        (imagen.raza?.toLowerCase().includes(search.toLowerCase()) || '') ||
        (imagen.puntaje?.toString().toLowerCase().includes(search.toLowerCase()) || '')
      );
    });
    setFilteredImagenes(filtered);
  };


  const handleAddEjemplar = () => {
    setImagenes([...imagenes, newEjemplar]);
    setNewEjemplar({ url: '', descripcion: '', codigoArete: '', nombre: '' });
    navigate('/RegEjemplar', { replace: true });
  };

  const handleModificar = (imagen) => {
    const arete_Code = imagen.codigo_arete;
   const cow_Name = imagen.nombre;
    navigate(`/ModEjemplar`,{ replace: true, 
      state: { arete_Code, cow_Name } 
     });
   };
  const handleEliminar = async (imagen) => {
    const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este ejemplar ?');
    
    if (confirmDelete) {
      const codigo_arete= imagen.codigo_arete
      const datos = {
        codigo_arete,
        observacion01: "Deleted",
      };
      const json = JSON.stringify(datos);
      try {
        // Hacer la solicitud DELETE al servidor
        const response = await axios.put(`https://cowsmyapi.mycowsrfi.com/api/vaca/delete/${codigo_arete}`, {
          json: json
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.token
          }
        });
        window.location.reload();
      } catch (error) {
        console.error('Error al eliminar la imagen:', error);
        alert('No se pudo eliminar el ejemplar . Intenta nuevamente.');
      }
    }
  };
  const handleHistorial = (imagen) => {
    const arete_Code = imagen.codigo_arete;
    const cow_Name = imagen.nombre;
    const score = imagen.puntaje;
     navigate(`/Historial`,{ replace: true, 
       state: { arete_Code, cow_Name, score } 
      });
    
    // Aquí puedes redirigir a la página de historial o mostrar un modal, etc.
  };

  const handleModRFI = (imagen ) => {
   const areteCode = imagen.codigo_arete;
   const cowName = imagen.nombre;
   const breed =imagen.raza;
   navigate('/RFI', { replace: true, 
      state: { areteCode, cowName, breed } 
     });
    // Aquí puedes redirigir a la página de modificación de RFI o mostrar un modal, etc.
  };
  const imprimirpdf = (codigo_arete) => {
    //alert('redirigiendo a '+raza)<a href="https://mycowsrfi.com/docs/ejemplares/ejemplar_.pdf" target="_blank" rel="noopener noreferrer">
    //                          <FontAwesomeIcon icon={faFilePdf} /> Manual de Usuario
    //                          </a>
    window.open('https://mycowsrfi.com/docs/ejemplares/ejemplar_'+codigo_arete+'.pdf');
   //navigate(`/Ejemplar/${codigo_arete}`);
  };

  const limpiarCache = async () => {
    if ('caches' in window) {
        // Obtiene todos los nombres de las cachés
        const cacheNames = await caches.keys();
        // Itera sobre cada caché
        for (const cacheName of cacheNames) {
            const cache = await caches.open(cacheName);
            // Elimina todas las entradas de la caché
            await cache.keys().then(async (keys) => {
                for (const request of keys) {
                    // Si es una imagen, puedes eliminarla
                    if (request.url.endsWith('.jpg') || request.url.endsWith('.png')) {
                        await cache.delete(request);
                    }
                }
            });
        }
    } else {
        console.error('La API de Cache Storage no está disponible en este navegador.');
    }
};

  return (
    <div>
      <Cabecera />
      <br/><br/><br/>
      <div className="listavacas">
        <div>
          <input
            type="search"
            value={search}
          onChange={(e) => setSearch(e.target.value)}
            placeholder="Buscar por nombre, código de arete o descripción"
          />
           <button className='qreje' onClick={() => navigate('/Reader')}>
    <FontAwesomeIcon icon={faQrcode} /> 
  </button>
  <button className='barras' onClick={() => navigate('/Reader')}>
    <FontAwesomeIcon icon={faBarcode} /> 
  </button>
  {tipoUsuario !== 'Observador' && (
  <button className='btn-add' onClick={handleAddEjemplar}>
    <FontAwesomeIcon icon={faPlus} /> Agregar Ejemplar
  </button>
)}
        </div>
        <main>
        <div className="gallery">
            {filteredImagenes.map((imagen, index) => (
              <div key={index} className="gallery-item card">
                <img src={imagen.foto} alt={imagen.foto} onClick={() => imprimirpdf(imagen.codigo_arete)} />
                <div className="description card-container">
                  <p>{imagen.nombre}</p>
                  <p>Código de arete: {imagen.codigo_arete}</p>
                  <p>Puntaje: {imagen.puntaje}</p>
                  <p>Raza: {imagen.raza}</p>
                  <div className="actions cta">
                  {tipoUsuario != 'Observador' && (
                    <button id="modificar" onClick={() => handleModificar(imagen)}>
                      <FontAwesomeIcon icon={faEdit} className="icono" />
                      <span>Mod. Ejemplar</span>
                    </button>
                    )}
                    {tipoUsuario != 'Observador' && (
                    <button id="Eliminar"  onClick={() => handleEliminar(imagen)}className="delete">
                      <FontAwesomeIcon icon={faTrash} className="icono" />
                      <span>Eliminar</span>
                    </button>
                    )}
                    {tipoUsuario != 'Observador' && (
                    <button id="historial" onClick={() => handleHistorial(imagen)}>
                    <FontAwesomeIcon icon={faHistory} className="icono" />
                    <span>Historial</span>
                    </button>
                    )}
                    {tipoUsuario != 'Observador' && (
                   <button id="modRFI" onClick={() => handleModRFI(imagen)}>
                   <FontAwesomeIcon icon={faEdit} className="icono" />
                   <span>Mod RFI</span>
                 </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Galeria;