import React, { useState, useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import { QRCodeCanvas } from 'qrcode.react';
import Cabecera from './CabeceraControler';
import '../Styles/generador.css'
import { Navigate, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function UnificadoCodeGenerator() {
  const [code, setCode] = useState('');
  const barcodeCanvasRef = useRef(null);
  const qrCodeCanvasRef = useRef(null);
  const location = useLocation();
  const { codigoArete } = location.state || {};
  const navigate = useNavigate();
  const [barcodeimage, setbarcodeimage] = useState(null);
  const [qrcodeimage, setqrcodeimage] = useState(null);
  let inputCode;

  const handleInputChange = () => {
    setCode(codigoArete);
  };
  const handleDownloadPDF = () => {
    navigate(`/Ejemplar/${codigoArete}`);
  }
  const handleDownload = () => {
    const barcodeDataUrl = barcodeCanvasRef.current.toDataURL();
    const qrCodeDataUrl = qrCodeCanvasRef.current.toDataURL();
    
    const link = document.createElement('a');
    link.href = barcodeDataUrl;
    link.download = `${codigoArete}-codigo-de-barras.png`;
    link.click();

    const qrLink = document.createElement('a');
    qrLink.href = qrCodeDataUrl;
    qrLink.download = `${codigoArete}-qrCode.png`;
    qrLink.click();
  };

  useEffect(() => {
    inputCode = document.getElementById('inputCode');
    inputCode.style.display = 'none';
    handleInputChange();
    if (barcodeCanvasRef.current && code.trim() !== '') {
      JsBarcode(barcodeCanvasRef.current, code, {
        format: 'CODE128',
        width: 3,
        height: 100,
        displayValue: true,
      });
    }
    //subida imagen Barcode
    setbarcodeimage(document.getElementById('imagenBarcode'));
    if (barcodeimage) {
      
      var barcodeDataUrl = barcodeimage.toDataURL('image/jpeg', 1.0); // 1.0 es la calidad de la imagen
      fetch(barcodeDataUrl)
      .then(res => res.blob())
      .then(blob => {
        // Crear un nuevo archivo a partir del Blob
        const newFile = new File([blob], codigoArete+'_barcode.jpg', { type: 'image/jpeg' });
        cargarBarcodeFTP(newFile);
        
        // Por ejemplo, puedes subirlo a un servidor o usarlo en tu aplicación
      })
      .catch(err => {
        console.error('Error al convertir el Data URL a Blob:', err);
      });
    }
    //subida imagen QR
    setqrcodeimage(document.getElementById('imagenQRcode'));
    if (qrcodeimage) {
      
      var qrcodeDataUrl = qrcodeimage.toDataURL('image/jpeg', 1.0); // 1.0 es la calidad de la imagen
      fetch(qrcodeDataUrl)
      .then(res => res.blob())
      .then(blob => {
        // Crear un nuevo archivo a partir del Blob
        const newqrFile = new File([blob], codigoArete+'_qrcode.jpg', { type: 'image/jpeg' });
        cargarQrcodeFTP(newqrFile);
        // Por ejemplo, puedes subirlo a un servidor o usarlo en tu aplicación
      })
      .catch(err => {
        console.error('Error al convertir el Data URL a Blob:', err);
      });
      //
    }
  }, [code]);

  const dataURLToBlob = (dataURL) => {
    var dataBarcodeImage = dataURL.split(',')[1];
    const binaryData = atob(dataBarcodeImage);
    const blob = new Blob([binaryData], { type: 'image/jpeg' });
    return blob
  };

  const cargarBarcodeFTP = async (imagencb) => {
    const newFileName = codigoArete+'_barcode.jpeg';
    const renamedFile = new File([imagencb], newFileName, { type: imagencb.type });
    const formData = new FormData();
    formData.append('image', renamedFile);
    try {
      const respuesta = await axios.post('https://cowsmyapi.mycowsrfi.com/api/subirImageBarras',formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Asegúrate de establecer el tipo de contenido
        },
      });
        //alert('La imagen se ha cargado correctamente.'); // Mensaje de éxito
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Mostrar los errores de validación
          console.error(error.response.data.errors);
          alert(`Error de validación: ${JSON.stringify(error.response.data.errors)}`);
        } else {
            console.error(error);
            alert(`Error al cargar la imagen: ${error.response?.data?.message || 'Error desconocido'}`);
        }
      }
    //console.log(renamedFile);
  }

  const cargarQrcodeFTP = async (imagencb) => {
    const newFileName = codigoArete+'_qrcode.jpeg';
    const renamedFile = new File([imagencb], newFileName, { type: imagencb.type });
    const formData = new FormData();
    formData.append('image', renamedFile);
    try {
      const respuesta = await axios.post('https://cowsmyapi.mycowsrfi.com/api/subirImageQR',formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Asegúrate de establecer el tipo de contenido
        },
      });
        //alert('La imagen se ha cargado correctamente.'); // Mensaje de éxito
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Mostrar los errores de validación
          console.error(error.response.data.errors);
          alert(`Error de validación: ${JSON.stringify(error.response.data.errors)}`);
        } else {
            console.error(error);
            alert(`Error al cargar la imagen: ${error.response?.data?.message || 'Error desconocido'}`);
        }
      }
    //console.log(renamedFile);
  }

  return (
    <div>
      <Cabecera />
     
      <div className='gefomdo'>
      <br/> <br/> <br/>
        <div className='generador '>
        <input id='inputCode' type="text" value={code} onChange={handleInputChange} placeholder="Ingrese el código"/>
          <div className='mensaje' style={{ marginTop: '20px', color: 'red' }}>Datos guardados correctamente. Por favor descargue los códigos.</div> {/* Mensaje siempre visible */}
          
          <div  style={{ marginTop: '20px' }}>
            <h3>Código de barras:</h3>
            <canvas id='imagenBarcode' ref={barcodeCanvasRef} width={300} height={100} />
            <h3>Código QR:</h3>
            <QRCodeCanvas id='imagenQRcode'
              ref={qrCodeCanvasRef}
              value={code}
              size={256}
              level="H"
              includeMargin={true}
            />
          </div>
          <button className='btnGenerador' onClick={handleDownload}>Descargar códigos</button>
          <button className='btnGenerador' onClick={handleDownloadPDF} style={{ marginTop: '10px' }}>
            Descargar PDF
          </button>
          <a href="#" onClick={() => navigate(-1)} className="regrsar">
            Regresar
          </a>
        </div> 
      </div>
    </div>
  );
}

export default UnificadoCodeGenerator;